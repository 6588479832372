<template>
  <div id="app" style="background-color: #FFFFFF; height: 100vh;">
    <div style="width: 100vw; text-align: center; color: #333333; font-size: 24px; padding-top: 16px">{{ orderDetail.oiName }}</div>

    <div style="width: 100vw; text-align: center; color: #ff6666; font-size: 20px; margin-top: 8px;">{{ data.diffWeight }}</div>

    <div style="width: 100vw; text-align: center; color: #A1A1A1; margin-top: 4px;">(差异值)</div>

    <div style="background-color: #f1f1f1; height: 1px; margin-left: 16px; margin-right: 16px; margin-top: 16px;"/>

    <div style="width: 100vw; display: flex; padding-top: 12px; padding-bottom: 12px">
      <div style="margin-left: 16px; color: #A1A1A1;">商户名称</div>
      <div style="flex: 1; text-align: end; margin-right: 16px; color: #333333;">张槎中心小学</div>
    </div>

    <div style="width: 100vw; display: flex; padding-top: 12px; padding-bottom: 12px">
      <div style="margin-left: 16px; color: #A1A1A1;">下单数量</div>
      <div style="flex: 1; text-align: end; margin-right: 16px; color: #333333;">
        {{ this.orderDetail.orderWeight + (this.orderDetail.model ? " " + this.orderDetail.model : "") }}
      </div>
    </div>

    <div style="width: 100vw; display: flex; padding-top: 12px; padding-bottom: 12px">
      <div style="margin-left: 16px; color: #A1A1A1;">称重数量</div>
      <div style="flex: 1; text-align: end; margin-right: 16px; color: #333333;">
        {{ this.data.weight + (this.orderDetail.model ? " " + this.orderDetail.model : "") }}
      </div>
    </div>

    <div style="width: 100vw; display: flex; padding-top: 12px; padding-bottom: 12px">
      <div style="margin-left: 16px; color: #A1A1A1;">称重时间</div>
      <div style="flex: 1; text-align: end; margin-right: 16px; color: #333333;">
        {{ (data.createdTime) | moment('yyyy-MM-DD HH:mm') }}
      </div>
    </div>

    <div style="width: 100vw; display: flex; padding-top: 12px; padding-bottom: 12px">
      <div style="margin-left: 16px; color: #A1A1A1;">订单编号</div>
      <div style="flex: 1; text-align: end; margin-right: 16px; color: #333333;">{{ data.orderNo }}</div>
    </div>

    <div style="width: 100vw; display: flex; padding-top: 12px; padding-bottom: 12px">
      <div style="margin-left: 16px; color: #A1A1A1;">配送时间</div>
      <div style="flex: 1; text-align: end; margin-right: 16px; color: #333333;">
        {{ (orderDetail.oiMealSendTime) | moment('yyyy-MM-DD HH:mm') }}
      </div>
    </div>

    <div class="bank_btn " style="position: absolute; bottom: 1.6vh; width: 100vw;">
      <van-button color="#1C8C69" @click="confirm" style="font-size: 16px">确认</van-button>
    </div>

    <div class="loading_all" v-if="loading">
      <van-loading type="spinner" color="#ffffff" vertical>加载中...</van-loading>
    </div>

  </div>
</template>

<script>
import {post} from "../../utils/http";
import {postProcurement} from "../../utils/httpProcurement";

import {Notify} from "vant";
import {toDecimal} from "../../utils/decimals";

export default {
  name: "WarningDetailAIBox",
  data() {
    return {
      id: '',
      loading: false,
      data: {
        diffWeight: 0.00,
      },
      orderDetail: {
        oiName: "",
      },
    }
  },
  mounted() {
    // 017fd9c00964000040289f9c7fd9bfa3
    this.id = this.$route.query.id;
    if (!this.id) {
      return;
    }

    this.fetchDetail(this.id);

  },
  methods: {
    fetchDetail(id) {
      if (!id) {
        return;
      }
      this.loading = true;
      post("/api/public/business/earlywarninginfo/:id/read".replace(":id", id), {})
          .then(res => {
            if (!res) {
              return;
            }
            console.log(res.data.content);
            this.data = JSON.parse(res.data.content);
            this.data.createdTime = res.createdTime;
            console.log(this.data)

            // 获取订单内容
            this.fetchOrderDetail();

          })
          .catch(err => {
            this.loading = false;
            Notify({type: 'warning', message: err.message});
          })
    },
    fetchOrderDetail() {
      postProcurement("/api/procurement/order/item/find", {
        orderNo: this.data.orderNo,
        productId: this.data.productId
      }, false)
          .then(res => {
            this.orderDetail = res.data;

            if (this.orderDetail && this.orderDetail.oiPrice > 0) {
              this.orderDetail.orderWeight = toDecimal(this.orderDetail.oiAmount / this.orderDetail.oiPrice);
            } else {
              this.orderDetail.orderWeight = 0.00;
            }

            // if (this.orderDetail.model && this.orderDetail.model === '斤') {
            //   this.data.weight = this.data.weight * 2;
            // }

            this.data.diffWeight = toDecimal(this.data.weight - this.orderDetail.orderWeight);

          })
          .catch(err => {
            Notify({type: 'warning', message: err.message});
          })
          .finally(() => {
            this.loading = false;

          })
    },
    getAlarm(alarmType) {
      switch (alarmType) {
        case "33":
          return "未佩戴口罩";
      }
    },
    confirm() {
      Notify({background: "#A1A1A1", message: "已确认情况"});
    },
  }
}
</script>

<style scoped>

</style>